
import { defineComponent, onMounted } from "vue";
import PaiementScolarite from "@/components/widgets/tables/PaiementScolarite.vue";
import PaiementActivites from "@/components/widgets/tables/PaiementActivites.vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "Paiement",
  components: {
    PaiementScolarite,
    PaiementActivites,
  },
  setup() {
    onMounted(() => {
      setCurrentPageTitle("dashboard.payment");
    });
  },
});
