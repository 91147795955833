
import { defineComponent, ref } from "vue";
import ApiService from "@/core/services/ApiService";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
export default defineComponent({
  name: "kt-widget-9",
  components: {},
  props: {
    widgetClasses: String,
  },
  setup() {
    const { t } = useI18n();
    /* eslint-disable */
    const store = useStore();
    const activityData = ref([]);

    ApiService.setHeader();
    ApiService.post("/students/activity/payment/filter", {
      query: {
        status: "active",
        student: window.localStorage.getItem("activeStudent"),
      },
      aggregation: [
        {
          $lookup: {
            from: "activities",
            localField: "activity",
            foreignField: "_id",
            as: "activity",
          },
        },
        {
          $lookup: {
            from: "services",
            localField: "activity.service",
            foreignField: "_id",
            as: "service",
          },
        },
        {
          $project: {
            service: { name: 1 },
            activity: {
              _id: 1,
              once: 1,
              inscription: 1,
              monthly: 1,
              start: 1,
              end: 1,
              designation: 1,
              order: 1,
            },
            inscription: 1,
            reduction: 1,
            months: 1,
          },
        },
      ],
    })
      .then(({ data }) => {
        data.forEach((element) => {
          if (element.months !== null) {
            let total = 0;
            let paid = 0;
            for (const property in element.months) {
              total += 1;
              if (element.months[property]) paid += 1;
            }
            element.progress = ((paid / total) * 100).toFixed(2);
          }
        });
        activityData.value = data;
        console.log("activity", activityData.value);
      })
      .catch((err) => {
        console.log("activity error", err);
      });

    return {
      activityData,
      t,
    };
  },
  methods: {
    getMonth(key) {
      let monthName = new Date(key);
      return monthName.toLocaleString("fr-FR", {
        month: "long",
      });
    },
    getMensuel(item) {
      return (
        item.activity[0].monthly -
        (item.activity[0].monthly * item.reduction.monthly) / 100
      );
    },
    getInscription(item) {
      if (item.activity[0].inscription)
        return (
          item.activity[0].inscription -
          (item.activity[0].inscription * item.reduction.inscription) / 100
        );
      return 0;
    },
  },
});
