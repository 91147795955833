
import { computed, defineComponent, onMounted, ref } from "vue";
import { MenuComponent } from "@/assets/ts/components/MenuComponent";
import { getCSSVariableValue } from "@/assets/ts/_utils";
import { useI18n } from "vue-i18n";
import moment from "moment";
import { event } from "vue-gtag";

export default defineComponent({
  name: "widget-1",
  components: {},
  props: {
    widgetClasses: String,
    widgetColor: String,
    strokeColor: String,
    classroom: String,
    MensuelAfterReduc: Number,
    InscriptionAfterReduc: Number,
    paymentStatus: Object,
  },
  setup(props) {
    const { t } = useI18n();

    const currentMonth = ref(moment().format("MMMM"));

    const totalPaid = computed(() => {
      let total = 0;
      if (props.paymentStatus && props.paymentStatus.paid)
        total = props.paymentStatus?.paid
          .map((p) => p.money)
          .reduce((a, b) => Number(a) + Number(b), 0);
      return total;
    });

    const totalNotPaidThisMonth = computed(() => {
      let total = 0;
      if (props.paymentStatus && props.paymentStatus.currentMonthPay)
        total = props.paymentStatus?.currentMonthPay
          .map((p) => p.money)
          .reduce((a, b) => a + b, 0);
      return total;
    });

    const visiblePaymentStatus = ref(false);

    const labelColor = getCSSVariableValue("--bs-gray-500");
    const borderColor = getCSSVariableValue("--bs-gray-200");
    const color = getCSSVariableValue("--bs-" + props.widgetColor);

    const strokeColor = ref(props.strokeColor);

    const chartOptions = {
      chart: {
        fontFamily: "inherit",
        type: "area",
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
        sparkline: {
          enabled: true,
        },
        dropShadow: {
          enabled: true,
          enabledOnSeries: undefined,
          top: 5,
          left: 0,
          blur: 3,
          color: strokeColor.value,
          opacity: 0.5,
        },
      },
      plotOptions: {},
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      fill: {
        type: "solid",
        opacity: 0,
      },
      stroke: {
        curve: "smooth",
        show: true,
        width: 3,
        colors: [strokeColor.value],
      },
      xaxis: {
        categories: ["Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug"],
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
          style: {
            colors: labelColor,
            fontSize: "12px",
          },
        },
        crosshairs: {
          show: false,
          position: "front",
          stroke: {
            color: borderColor,
            width: 1,
            dashArray: 3,
          },
        },
      },
      yaxis: {
        min: 0,
        max: 80,
        labels: {
          show: false,
          style: {
            colors: labelColor,
            fontSize: "12px",
          },
        },
      },
      states: {
        normal: {
          filter: {
            type: "none",
            value: 0,
          },
        },
        hover: {
          filter: {
            type: "none",
            value: 0,
          },
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: "none",
            value: 0,
          },
        },
      },
      tooltip: {
        style: {
          fontSize: "12px",
        },
        y: {
          formatter: function (val) {
            return "$" + val + " thousands";
          },
        },
        marker: {
          show: false,
        },
      },
      markers: {
        colors: [color],
        strokeColor: [strokeColor.value],
        strokeWidth: 3,
      },
    };

    console.log("classroom", props.classroom);

    onMounted(() => {
      MenuComponent.reinitialization();
    });

    const OpenPaymentStatus = () => {
      visiblePaymentStatus.value = true;
      event("View Statut du paiement", {
        event_category: "Statut du paiement",
        event_label: "Paiement section",
        value: 1,
      });
    };

    return {
      chartOptions,
      t,
      totalPaid,
      totalNotPaidThisMonth,
      visiblePaymentStatus,
      currentMonth,
      OpenPaymentStatus,
    };
  },
  methods: {
    getMonth(key) {
      let monthName = new Date(key);
      return monthName.toLocaleString("fr-FR", {
        month: "long",
      });
    },
  },
});
