import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "row gy-5 gx-xl-8 pb-5" }
const _hoisted_2 = { class: "col-xxl-7" }
const _hoisted_3 = { class: "card-header border-0 pt-5" }
const _hoisted_4 = { class: "card-title align-items-start flex-column" }
const _hoisted_5 = { class: "card-label fw-bolder fs-3 mb-1" }
const _hoisted_6 = {
  class: "card-toolbar",
  "data-bs-toggle": "tooltip",
  "data-bs-placement": "top",
  "data-bs-trigger": "hover",
  title: "Click to add a user"
}
const _hoisted_7 = { class: "card-body py-3" }
const _hoisted_8 = { class: "table-responsive" }
const _hoisted_9 = { class: "table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4" }
const _hoisted_10 = { class: "fw-bolder text-muted" }
const _hoisted_11 = { class: "min-w-150px" }
const _hoisted_12 = { class: "min-w-140px" }
const _hoisted_13 = { key: 0 }
const _hoisted_14 = { class: "col-xxl-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Informations = _resolveComponent("Informations")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: _normalizeClass(["card", _ctx.widgetClasses])
      }, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("h3", _hoisted_4, [
            _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.t("payment.scolarityPayment")), 1)
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("a", {
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.generatePdf && _ctx.generatePdf(...args))),
              class: "btn btn-sm btn-light-primary",
              "data-bs-toggle": "modal",
              "data-bs-target": "#kt_modal_invite_friends"
            }, _toDisplayString(_ctx.t("payment.print")), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("table", _hoisted_9, [
              _createElementVNode("thead", null, [
                _createElementVNode("tr", _hoisted_10, [
                  _createElementVNode("th", _hoisted_11, _toDisplayString(_ctx.t("payment.month")), 1),
                  _createElementVNode("th", _hoisted_12, _toDisplayString(_ctx.t("payment.state")), 1)
                ])
              ]),
              _createElementVNode("tbody", null, [
                (_ctx.paymentsData)
                  ? (_openBlock(), _createElementBlock("tr", _hoisted_13, [
                      _createElementVNode("td", null, _toDisplayString(_ctx.t("payment.inscription")), 1),
                      _createElementVNode("td", null, [
                        _createElementVNode("span", {
                          class: _normalizeClass(
                        _ctx.paymentsData.inscription
                          ? 'badge badge-light-success'
                          : 'badge badge-light-danger'
                      )
                        }, _toDisplayString(_ctx.paymentsData.inscription ? "payé" : "non payé"), 3)
                      ])
                    ]))
                  : _createCommentVNode("", true),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.paymentsData.months, (month, key, index) => {
                  return (_openBlock(), _createElementBlock("tr", { key: index }, [
                    _createElementVNode("td", null, [
                      _createElementVNode("span", null, _toDisplayString(_ctx.getMonth(key)), 1)
                    ]),
                    _createElementVNode("td", null, [
                      _createElementVNode("span", {
                        class: _normalizeClass(
                          month
                            ? 'badge badge-light-success'
                            : 'badge badge-light-danger'
                        )
                      }, _toDisplayString(month ? "payé" : "non payé"), 3)
                    ])
                  ]))
                }), 128))
              ])
            ])
          ])
        ])
      ], 2)
    ]),
    _createElementVNode("div", _hoisted_14, [
      _createVNode(_component_Informations, {
        "widget-classes": "card-xl-stretch mb-xl-8",
        "widget-color": "danger",
        "chart-height": "200",
        "stroke-color": "#cb1e46",
        classroom: _ctx.classroom,
        MensuelAfterReduc: _ctx.MensuelAfterReduc,
        InscriptionAfterReduc: _ctx.InscriptionAfterReduc,
        paymentStatus: _ctx.paymentStatus
      }, null, 8, ["classroom", "MensuelAfterReduc", "InscriptionAfterReduc", "paymentStatus"])
    ])
  ]))
}