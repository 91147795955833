import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "card-header border-0 pt-5" }
const _hoisted_2 = { class: "card-title align-items-start flex-column" }
const _hoisted_3 = { class: "card-label fw-bolder fs-3 mb-1" }
const _hoisted_4 = { class: "card-body py-3" }
const _hoisted_5 = { class: "table-responsive" }
const _hoisted_6 = { class: "table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4" }
const _hoisted_7 = { class: "fw-bolder text-muted" }
const _hoisted_8 = { class: "min-w-150px" }
const _hoisted_9 = { class: "min-w-150px" }
const _hoisted_10 = { class: "min-w-140px" }
const _hoisted_11 = { class: "min-w-120px" }
const _hoisted_12 = { class: "d-flex align-items-center" }
const _hoisted_13 = { class: "d-flex justify-content-start flex-column" }
const _hoisted_14 = {
  href: "#",
  class: "text-dark fw-bolder text-hover-primary fs-6"
}
const _hoisted_15 = {
  key: 0,
  class: "text-muted fw-bold text-muted d-block fs-7"
}
const _hoisted_16 = {
  key: 1,
  class: "text-muted fw-bold text-muted d-block fs-7"
}
const _hoisted_17 = { class: "d-flex align-items-center" }
const _hoisted_18 = { class: "d-flex justify-content-start flex-column" }
const _hoisted_19 = { class: "text-muted text-hover-primary fw-bold text-muted d-block fs-7" }
const _hoisted_20 = { class: "text-dark" }
const _hoisted_21 = { class: "text-muted text-hover-primary fw-bold text-muted d-block fs-7" }
const _hoisted_22 = { class: "text-dark" }
const _hoisted_23 = { class: "text-end" }
const _hoisted_24 = { class: "d-flex flex-column w-100 me-2" }
const _hoisted_25 = { class: "d-flex flex-stack mb-2" }
const _hoisted_26 = { class: "text-muted me-2 fs-7 fw-bold" }
const _hoisted_27 = { class: "progress h-6px w-100" }
const _hoisted_28 = ["aria-valuenow"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["card", _ctx.widgetClasses])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h3", _hoisted_2, [
        _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.t("payment.activityPayment")), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("table", _hoisted_6, [
          _createElementVNode("thead", null, [
            _createElementVNode("tr", _hoisted_7, [
              _createElementVNode("th", _hoisted_8, _toDisplayString(_ctx.t("payment.activityName")), 1),
              _createElementVNode("th", _hoisted_9, _toDisplayString(_ctx.t("payment.fees")), 1),
              _createElementVNode("th", _hoisted_10, _toDisplayString(_ctx.t("payment.month")), 1),
              _createElementVNode("th", _hoisted_11, _toDisplayString(_ctx.t("payment.progress")), 1)
            ])
          ]),
          _createElementVNode("tbody", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.activityData, (item, index) => {
              return (_openBlock(), _createElementBlock("tr", { key: index }, [
                _createElementVNode("td", null, [
                  _createElementVNode("div", _hoisted_12, [
                    _createElementVNode("div", _hoisted_13, [
                      _createElementVNode("a", _hoisted_14, _toDisplayString(item.activity[0].designation || ""), 1),
                      (item.activity[0].once)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_15, _toDisplayString(_ctx.t("payment.singlePayment")), 1))
                        : (_openBlock(), _createElementBlock("span", _hoisted_16, _toDisplayString(_ctx.t("payment.monthlyPayment")), 1))
                    ])
                  ])
                ]),
                _createElementVNode("td", null, [
                  _createElementVNode("div", _hoisted_17, [
                    _createElementVNode("div", _hoisted_18, [
                      _createElementVNode("label", _hoisted_19, [
                        _createElementVNode("span", _hoisted_20, _toDisplayString(_ctx.t("payment.inscription")), 1),
                        _createTextVNode(": " + _toDisplayString(_ctx.getInscription(item)), 1)
                      ]),
                      _createElementVNode("label", _hoisted_21, [
                        _createElementVNode("span", _hoisted_22, _toDisplayString(_ctx.t("payment.monthly")), 1),
                        _createTextVNode(": " + _toDisplayString(_ctx.getMensuel(item)), 1)
                      ])
                    ])
                  ])
                ]),
                _createElementVNode("td", null, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.months, (month, key, index) => {
                    return (_openBlock(), _createElementBlock("span", {
                      key: index,
                      class: _normalizeClass(
                      month
                        ? 'badge badge-light-success'
                        : 'badge badge-light-danger'
                    )
                    }, _toDisplayString(_ctx.getMonth(key)), 3))
                  }), 128))
                ]),
                _createElementVNode("td", _hoisted_23, [
                  _createElementVNode("div", _hoisted_24, [
                    _createElementVNode("div", _hoisted_25, [
                      _createElementVNode("span", _hoisted_26, _toDisplayString(item.progress == "NaN" ? 0 : item.progress) + "% ", 1)
                    ]),
                    _createElementVNode("div", _hoisted_27, [
                      _createElementVNode("div", {
                        class: _normalizeClass(["progress-bar", `bg-green`]),
                        role: "progressbar",
                        style: _normalizeStyle({ width: item.progress + '%' }),
                        "aria-valuenow": item.progress,
                        "aria-valuemin": "0",
                        "aria-valuemax": "100"
                      }, null, 12, _hoisted_28)
                    ])
                  ])
                ])
              ]))
            }), 128))
          ])
        ])
      ])
    ])
  ], 2))
}