
import { defineComponent, onMounted, ref } from "vue";
import ApiService from "@/core/services/ApiService";
import { useStore } from "vuex";
import Informations from "@/components/widgets/lists/Informations.vue";
import { useI18n } from "vue-i18n";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import Amiri from "raw-loader!@/assets/fonts/Amiri.txt";
import { event } from "vue-gtag";

export default defineComponent({
  name: "kt-widget-9",
  components: {
    Informations,
  },
  props: {
    widgetClasses: String,
  },
  setup() {
    const { t } = useI18n();
    const paymentsData = ref({});
    const classroom = ref("non inscrit");
    const MensuelAfterReduc = ref(1);
    const InscriptionAfterReduc = ref(2);
    const studentID = window.localStorage.getItem("activeStudent");

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const paymentStatus = ref<Array<any>>([]);

    ApiService.post("/classrooms/payment/filter", {
      query: {
        student: window.localStorage.getItem("activeStudent"), //change this dynamically
      },
      aggregation: [
        {
          $lookup: {
            from: "classrooms",
            localField: "classRoom",
            foreignField: "_id",
            as: "classroom",
          },
        },
        {
          $project: {
            _id: 1,
            months: 1,
            inscription: 1,
            reduction: 1,
            classroom: {
              _id: 1,
              name: 1,
              start: 1,
              end: 1,
              inscription: 1,
              monthly: 1,
            },
          },
        },
      ],
    })
      .then(({ data }) => {
        if (data[0]) {
          paymentsData.value = data[0];
          console.log("payment", paymentsData.value);
          MensuelAfterReduc.value =
            data[0].classroom[0].monthly -
            (data[0].classroom[0].monthly * data[0].reduction.monthly) / 100;
          InscriptionAfterReduc.value =
            data[0].classroom[0].inscription -
            (data[0].classroom[0].inscription * data[0].reduction.inscription) /
              100;
          classroom.value = data[0].classroom[0].name;
        }
      })
      .finally(() => {
        console.log("payment", paymentsData.value);
      });

    ApiService.get(
      "/paymentStatus/" + window.localStorage.getItem("activeStudent")
    ).then(({ data }) => {
      if (data) {
        paymentStatus.value = data;
      }
    });

    // print bills

    const parents = ref<any[]>([]);
    const data: any[] = [];
    const filtredTable = ref<any[]>([]);

    const FISCAL = parseFloat(process.env.VUE_APP_FISCAL);
    const TVA = parseFloat(process.env.VUE_APP_TVA);

    onMounted(async () => {
      // get parents data
      ApiService.post("/parents/filter", {
        query: {
          students: studentID,
        },
        aggregation: {
          $project: {
            father: 1,
            mother: 1,
          },
        },
      })
        .then((res) => (parents.value = res.data))
        .catch();

      await ApiService.post("/money/activityreceipt/filter", {
        query: { status: { $ne: "cancelled" } },
        aggregation: [
          {
            $lookup: {
              from: "activityfees",
              let: { activityId: "$activityFees" },
              pipeline: [
                {
                  $match: {
                    $expr: {
                      $and: [
                        { $eq: ["$_id", "$$activityId"] },
                        {
                          $eq: ["$student", { $toObjectId: studentID }],
                        },
                      ],
                    },
                  },
                },
              ],
              as: "activityfees",
            },
          },
          {
            $unwind: { path: "$activityfees" },
          },
          {
            $lookup: {
              from: "students",
              localField: "activityfees.student",
              foreignField: "_id",
              as: "student",
            },
          },
          {
            $lookup: {
              from: "activities",
              localField: "activityfees.activity",
              foreignField: "_id",
              as: "activity",
            },
          },
          {
            $lookup: {
              from: "receipttypes",
              localField: "receiptType",
              foreignField: "_id",
              as: "receiptType",
            },
          },

          {
            $lookup: {
              from: "cheques",
              localField: "cheque",
              foreignField: "_id",
              as: "cheque",
            },
          },
          {
            $project: {
              _id: 1,
              date: 1,
              serial: 1,
              months: 1,
              onBillReduction: 1,
              onInscription: 1,
              student: {
                _id: 1,
                firstName: 1,
                lastName: 1,
                parent: 1,
              },
              activity: {
                _id: 1,
                designation: 1,
                monthly: 1,
                inscription: 1,
              },
              activityfees: {
                _id: 1,
                reduction: 1,
                inscription: 1,
                months: 1,
              },
              receiptType: { name: 1 },
              cheque: { $arrayElemAt: ["$cheque", 0] },
            },
          },
        ],
      })
        .then((res) => {
          res.data.map((elem) => {
            data.push(
              activityFormater({
                ...elem,
                activity: elem.activity[0],
                activityfees: elem.activityfees,
                receiptType: elem.receiptType[0],
                student: elem.student[0],
              })
            );
          });
        })
        .catch();

      await ApiService.post("/money/activitybill/filter", {
        query: { status: { $ne: "cancelled" } },
        aggregation: [
          {
            $lookup: {
              from: "activityfees",
              let: { activityId: "$activityFees" },
              pipeline: [
                {
                  $match: {
                    $expr: {
                      $and: [
                        { $eq: ["$_id", "$$activityId"] },
                        {
                          $eq: ["$student", { $toObjectId: studentID }],
                        },
                      ],
                    },
                  },
                },
              ],
              as: "activityfees",
            },
          },
          {
            $unwind: { path: "$activityfees" },
          },
          {
            $lookup: {
              from: "students",
              localField: "activityfees.student",
              foreignField: "_id",
              as: "student",
            },
          },
          {
            $lookup: {
              from: "activities",
              localField: "activityfees.activity",
              foreignField: "_id",
              as: "activity",
            },
          },
          {
            $lookup: {
              from: "receipttypes",
              localField: "receiptType",
              foreignField: "_id",
              as: "receiptType",
            },
          },
          {
            $lookup: {
              from: "cheques",
              localField: "cheque",
              foreignField: "_id",
              as: "cheque",
            },
          },
          {
            $project: {
              _id: 1,
              date: 1,
              serial: 1,
              months: 1,
              onBillReduction: 1,
              onInscription: 1,
              student: {
                _id: 1,
                firstName: 1,
                lastName: 1,
                parent: 1,
              },
              activity: {
                _id: 1,
                designation: 1,
                monthly: 1,
                inscription: 1,
              },
              activityfees: {
                _id: 1,
                reduction: 1,
                inscription: 1,
                months: 1,
              },
              receiptType: { name: 1 },
              cheque: { $arrayElemAt: ["$cheque", 0] },
            },
          },
        ],
      })
        .then((res) => {
          res.data.map((elem) => {
            data.push(
              activityFormater(
                {
                  ...elem,
                  activity: elem.activity[0],
                  activityfees: elem.activityfees,
                  receiptType: elem.receiptType[0],
                  student: elem.student[0],
                },
                true
              )
            );
          });
        })
        .catch();

      await ApiService.post("/money/classroomreceipt/filter", {
        query: { status: { $ne: "cancelled" } },
        aggregation: [
          {
            $lookup: {
              from: "classroompayments",
              /*   localField: "classRoomPayment",
              foreignField: "_id", */
              let: { classRoomPayment: "$classRoomPayment" },
              pipeline: [
                {
                  $match: {
                    $expr: {
                      $and: [
                        { $eq: ["$_id", "$$classRoomPayment"] },
                        {
                          $eq: ["$student", { $toObjectId: studentID }],
                        },
                        /*    { $eq: ["$inscription", "true"] }, */
                      ],
                    },
                  },
                },
                /*   { $project: { _id: 1, student: 1, activity: 1 } }, */
              ],
              as: "classRoomPayment",
            },
          },
          {
            $unwind: { path: "$classRoomPayment" },
          },
          {
            $lookup: {
              from: "students",
              localField: "classRoomPayment.student",
              foreignField: "_id",
              as: "student",
            },
          },
          {
            $lookup: {
              from: "classrooms",
              localField: "classRoomPayment.classRoom",
              foreignField: "_id",
              as: "classRoom",
            },
          },
          {
            $lookup: {
              from: "receipttypes",
              localField: "receiptType",
              foreignField: "_id",
              as: "receiptType",
            },
          },
          {
            $lookup: {
              from: "cheques",
              localField: "cheque",
              foreignField: "_id",
              as: "cheque",
            },
          },
          {
            $project: {
              _id: 1,
              date: 1,
              serial: 1,
              months: 1,
              onBillReduction: 1,
              onInscription: 1,
              student: {
                _id: 1,
                firstName: 1,
                lastName: 1,
                parent: 1,
              },
              classRoom: {
                _id: 1,
                name: 1,
                monthly: 1,
                inscription: 1,
              },
              classRoomPayment: {
                _id: 1,
                reduction: 1,
                inscription: 1,
                months: 1,
              },
              receiptType: { name: 1 },
              cheque: { $arrayElemAt: ["$cheque", 0] },
            },
          },
        ],
      })
        .then((res) => {
          res.data.map((elem) => {
            data.push(
              classRoomPFormater({
                ...elem,
                classRoom: elem.classRoom[0],
                classRoomPayment: elem.classRoomPayment,
                receiptType: elem.receiptType[0],
                student: elem.student[0],
              })
            );
          });
        })
        .catch();

      await ApiService.post("/money/classroombill/filter", {
        query: { status: { $ne: "cancelled" } },
        aggregation: [
          {
            $lookup: {
              from: "classroompayments",
              let: { classRoomPayment: "$classRoomPayment" },
              pipeline: [
                {
                  $match: {
                    $expr: {
                      $and: [
                        { $eq: ["$_id", "$$classRoomPayment"] },
                        {
                          $eq: ["$student", { $toObjectId: studentID }],
                        },
                      ],
                    },
                  },
                },
              ],
              as: "classRoomPayment",
            },
          },
          {
            $unwind: { path: "$classRoomPayment" },
          },
          {
            $lookup: {
              from: "students",
              localField: "classRoomPayment.student",
              foreignField: "_id",
              as: "student",
            },
          },
          {
            $lookup: {
              from: "classrooms",
              localField: "classRoomPayment.classRoom",
              foreignField: "_id",
              as: "classRoom",
            },
          },
          {
            $lookup: {
              from: "receipttypes",
              localField: "receiptType",
              foreignField: "_id",
              as: "receiptType",
            },
          },
          {
            $lookup: {
              from: "cheques",
              localField: "cheque",
              foreignField: "_id",
              as: "cheque",
            },
          },
          {
            $project: {
              _id: 1,
              date: 1,
              serial: 1,
              months: 1,
              onBillReduction: 1,
              onInscription: 1,
              student: {
                _id: 1,
                firstName: 1,
                lastName: 1,
                parent: 1,
              },
              classRoom: {
                _id: 1,
                name: 1,
                monthly: 1,
                inscription: 1,
              },
              classRoomPayment: {
                _id: 1,
                reduction: 1,
                inscription: 1,
                months: 1,
              },
              receiptType: { name: 1 },
              cheque: { $arrayElemAt: ["$cheque", 0] },
            },
          },
        ],
      })
        .then((res) => {
          res.data.map((elem) => {
            try {
              data.push(
                classRoomPFormater(
                  {
                    ...elem,
                    classRoom: elem.classRoom[0],
                    classRoomPayment: elem.classRoomPayment,
                    receiptType: elem.receiptType[0],
                    student: elem.student[0],
                    onBillReduction: elem.onBillReduction,
                  },
                  true
                )
              );
            } catch {
              return;
            }
          });
        })
        .catch();

      filtredTable.value = data;
    });

    const store = useStore();

    const generatePdf = () => {
      event("Imp Liste réglement", {
        event_category: "Impression PDF",
        event_label: "Paiement section",
        value: 1,
      });

      const imgData = new Image();
      imgData.src = store.getters.serverConfigUrl.building.logo;

      const doc = new jsPDF("p", "pt", "a4");
      const pdfWidth = doc.internal.pageSize.getWidth();

      doc.addFileToVFS("Amiri-normal.ttf", Amiri);
      doc.addFont("Amiri-normal.ttf", "Amiri", "normal");

      doc.setFont("Amiri");
      const date = new Date();
      const titre = "Liste réglement";
      const options: Intl.DateTimeFormatOptions = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      doc.setFontSize(15);

      const width = doc.getTextWidth(titre);
      doc.text(titre, pdfWidth / 2 - width / 2, 70);

      doc.setFontSize(10);
      doc.addImage(imgData, "JPEG", 20, 8, 40, 40);
      const datewidth = doc.getTextWidth(
        "Imprimé le : " + " " + date.toLocaleDateString("fr-FR", options)
      );
      doc.text(
        "Imprimé le : " + " " + date.toLocaleDateString("fr-FR", options),
        pdfWidth - datewidth - 20,
        15
      );
      doc.setFontSize(12);
      let arr: any[] = [];
      const array: any[] = [];
      let sommeHT = 0;
      let sommeTTC = 0;
      for (let i = 0; i < filtredTable.value.length; i++) {
        arr.push(filtredTable.value[i].serial.split("/")[1]);
        arr.push(filtredTable.value[i].nomClient);
        arr.push(filtredTable.value[i].typeReglement);
        arr.push(filtredTable.value[i].modePaiement);
        arr.push(filtredTable.value[i].montantHT);
        sommeHT += parseFloat(filtredTable.value[i].montantHT);
        arr.push(filtredTable.value[i].montantTTC);
        sommeTTC += parseFloat(filtredTable.value[i].montantTTC);
        array.push(arr);
        arr = [];
      }
      autoTable(doc, {
        styles: { font: "Amiri", fontSize: 10 },
        startY: doc.pageCount > 1 ? doc.autoTableEndPosY() + 20 : 80,
        head: [
          [
            "Numéro",
            "Nom du client",
            "Type de réglement",
            "Mode de paiement",
            "Montant HT",
            "Montant TTC",
          ],
        ],
        body: array,
        foot: [
          ["", "", "", "Total : ", sommeHT.toFixed(2), sommeTTC.toFixed(2)],
        ],
      });

      //page numbering
      const pages = doc.getNumberOfPages();
      const pageWidth = doc.internal.pageSize.width;
      const pageHeight = doc.internal.pageSize.height;
      doc.setFontSize(8); //Optional

      for (let j = 1; j < pages + 1; j++) {
        let horizontalPos = pageWidth / 2;
        let verticalPos = pageHeight - 10;
        doc.setPage(j);
        doc.text(`Page ${j} / ${pages}`, horizontalPos, verticalPos, {
          align: "center",
        });
      }
      //end page numbering
      doc.save("Liste_Réglement.pdf");
    };

    const classRoomPFormater = (paymentData: any, bill = false) => {
      const reduction = {
        inscription: paymentData.classRoomPayment.reduction.inscription,
        monthly: paymentData.classRoomPayment.reduction.monthly,
      };
      let money = 0;
      if (paymentData.onInscription) {
        const inscriptionFees = parseInt(paymentData.classRoom.inscription);
        money =
          inscriptionFees - (inscriptionFees * reduction.inscription) / 100;
      }
      if (paymentData.months) {
        const nbMonth = Object.keys(paymentData.months).length;
        const monthFees = nbMonth * paymentData.classRoom.monthly;
        money = money + monthFees - (monthFees * reduction.monthly) / 100;
      }
      const onBillReduction = paymentData.onBillReduction
        ? paymentData.onBillReduction
        : 0;
      money = money - (money * onBillReduction) / 100;
      const parent = parents.value.filter(
        (e) => e._id == paymentData.student.parent
      )[0];
      const id = paymentData._id;
      let data = {
        onBillReduction: onBillReduction,
        serial: paymentData.serial,
        url: bill ? "/bills/classroom/" + id : "/receipt/classroom/" + id,
        _id: id,
        nomClient:
          parent.mother.firstName +
          " " +
          parent.mother.lastName +
          " ET " +
          parent.father.firstName +
          " " +
          parent.father.lastName,
        classRoom: paymentData.classRoom,
        student: paymentData.student,
        typeReglement: "Scolarité/" + paymentData.classRoom.name,
        modePaiement: paymentData.receiptType.name,
        montantHT: money - money * TVA,
        montantTTC: money + (bill ? FISCAL : 0),
        classRoomPayment: paymentData.classRoomPayment,
        bill: bill,
        cheque: paymentData.cheque ? paymentData.cheque : null,
        date: paymentData.date,
        info: {
          onInscription: Boolean(paymentData.onInscription),
          months: paymentData.months,
        },
      };
      return data;
    };

    const activityFormater = (paymentData: any, bill = false): any => {
      const reduction = {
        inscription: paymentData.activityfees.reduction.inscription,
        monthly: paymentData.activityfees.reduction.monthly,
      };
      let money = 0;
      if (paymentData.onInscription) {
        const inscriptionFees = parseInt(paymentData.activity.inscription);
        money =
          inscriptionFees - (inscriptionFees * reduction.inscription) / 100;
      }
      if (paymentData.months) {
        const nbMonth = Object.keys(paymentData.months).length;
        const monthFees = nbMonth * paymentData.activity.monthly;
        money = money + monthFees - (monthFees * reduction.monthly) / 100;
      }
      const onBillReduction = paymentData.onBillReduction
        ? paymentData.onBillReduction
        : 0;
      money = money - (money * onBillReduction) / 100;
      const parent = parents.value.filter(
        (e) => e._id == paymentData.student.parent
      )[0];
      const id = paymentData._id;
      let data = {
        serial: paymentData.serial,
        url: bill ? "/bills/activity/" + id : "/receipt/activity/" + id,
        _id: id,
        nomClient:
          parent.mother.firstName +
          " " +
          parent.mother.lastName +
          " ET " +
          parent.father.firstName +
          " " +
          parent.father.lastName,
        typeReglement: "Activité/" + paymentData.activity.designation,
        modePaiement: paymentData.receiptType.name,
        activity: paymentData.activity,
        student: paymentData.student,
        montantHT: money - money * TVA,
        montantTTC: money + (bill ? FISCAL : 0),
        activityfees: paymentData.activityfees,
        bill: bill,
        cheque: paymentData.cheque ? paymentData.cheque : null,
        date: paymentData.date,
        info: {
          onInscription: Boolean(paymentData.onInscription),
          months: paymentData.months,
        },
      };
      return data;
    };

    return {
      paymentsData,
      classroom,
      InscriptionAfterReduc,
      MensuelAfterReduc,
      t,
      generatePdf,
      paymentStatus,
    };
  },
  methods: {
    getMonth(key) {
      let monthName = new Date(key);
      return monthName.toLocaleString("fr-FR", {
        month: "long",
      });
    },
  },
});
